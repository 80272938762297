import { useRef } from 'react'
import useTypewriterOnScroll from '@/utils/useTypewriterOnScroll'
import useRevealOnScroll from '@/utils/useRevealOnScroll'
import { motion } from 'framer-motion'

import styles from '@/components/home/v2/EVHCSection.module.css'
import TestIllustration from '@/components/home/v2/illustrations/TestIllustration'
import AnalysisIllustration from '@/components/home/v2/illustrations/AnalysisIllustration'
import ReportIllustration from '@/components/home/v2/illustrations/ReportIllustration'
import AnimatedTriangles from '@/components/home/v2/illustrations/AnimatedTrianges'
import { FaArrowRight } from 'react-icons/fa'
import Link from 'next/link'

function EVHCSection() {
  const messageRef2 = useRef(null)

  const messageRef3 = useRef(null)
  const messageRef4 = useRef(null)
  const messageRef5 = useRef(null)

  const descriptionRef = useRef(null)

  const title_2 = 'EV Health Checker'

  const title_3 = 'Test'
  const title_4 = 'Analysis'
  const title_5 = 'Report'

  const messages = [title_2, title_3, title_4, title_5]
  const refs = [messageRef2, messageRef3, messageRef4, messageRef5]

  const delays = [0, 0, 0, 0]

  const revealRefs = [descriptionRef]
  const revealDelays = [500]

  //useTypewriterOnScroll(refs, messages, styles, 500);
  useTypewriterOnScroll(refs, messages, styles, delays)
  const { revealedIndices } = useRevealOnScroll(revealRefs, revealDelays)

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentRowTwo}>
          <div className={styles.col_1}>
            <h2 ref={messageRef2} className={styles.h2}></h2>
            <motion.div
              ref={descriptionRef}
              className={styles.description}
              initial={{ opacity: 0, y: 20 }}
              animate={revealedIndices.has(0) ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5 }}
            >
              A self-service mobile app for boosting confidence in the used EV market by measuring and certifying
              battery health. We use mobile telematics to collect driving data in order to measure key battery
              indicators.
            </motion.div>

            <Link className={styles.CWLink} href="/ev-health-checker">
              <div className={styles.CWLinkLabel}>Find out more</div>
              <FaArrowRight className={styles.CWLinkIcon} size={24} />
            </Link>
          </div>
        </div>

        <div className={styles.contentRow}>
          <div className={styles.col_1}>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <TestIllustration className={styles.cwIllustration} />
              </div>
              <div className={styles.cardInfo}>
                <div ref={messageRef3} className={styles.cardTitle}></div>
                <div className={styles.cardText}>
                  Simply drive as you normally would with the ClearWatt app passively running in the background. Our
                  cutting-edge mobile telematics technology will seamlessly gather driving data, helping us gain
                  insights into your EV's performance.
                </div>
              </div>
            </div>
          </div>
          <div className={styles.middleCol}>
            <div>
              <AnimatedTriangles />
            </div>
            <div>
              <hr />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <AnalysisIllustration className={styles.cwIllustration} />
              </div>
              <div className={styles.cardInfo}>
                <div ref={messageRef4} className={styles.cardTitle}></div>
                <div className={styles.cardText}>
                  We analyse your data using the ClearWatt Engine, a patent-pending data analyser leveraging physics,
                  chemistry and machine learning to understand the performance of your EV.
                </div>
              </div>
            </div>
          </div>

          <div className={styles.middleCol}>
            <div>
              <AnimatedTriangles />
            </div>
            <div>
              <hr />
            </div>
          </div>

          <div className={styles.col_3}>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <ReportIllustration className={styles.cwIllustration} />
              </div>
              <div className={styles.cardInfo}>
                <div ref={messageRef5} className={styles.cardTitle}></div>
                <div className={styles.cardText}>
                  We use results outputted from the ClearWatt Engine to build your unique ClearWatt EV Health Report.
                  This provides an instant snapshot of the car's performance as well as independent, 3rd party
                  reassurance on the condition of the battery.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EVHCSection
