import styles from "@/components/home/v2/illustrations/ReportIllustration.module.css"
import Report from "@/components/svgs/icons/v2/Report";

const ReportIllustration = () => {

  return (

    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.element}>
          <Report/>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>ClearWatt Report</div>
      </div>
    </div>

  )
}

export default ReportIllustration;

