import {useState, useEffect, useRef} from "react";
import styles from "@/components/ui/search/SearchComponentXS.module.css";
import {authenticatedFetchAPI} from "@/pages/api/clearwattApi";
import useDebounce from "@/utils/useDebounce";

export default function SearchComponentXS({onSearch}) {
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const searchContainerRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isReFocused, setIsReFocused] = useState(false);
  const [searchFinished, setSearchFinished] = useState(false);
  const searchInputRef = useRef();
  const debouncedSearchTerm = useDebounce(searchInput, 300);


  useEffect(() => {
    const bodyClasses = document.body.classList;
    if (isFocused) {
      bodyClasses.add("modalOpen");
    } else {
      bodyClasses.remove("modalOpen");
    }
    return () => {
      bodyClasses.remove("modalOpen");
    };
  }, [isFocused]);


  useEffect(() => {
    // Check if the click was outside the component
    function handleClickOutside(event) {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setSearchResults([]);
        setIsFocused(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // note that the dependency array is empty, this useEffect will run once after the component mounts and then cleanup when it unmounts

  /*useEffect(() => {
    if ((isReFocused || !isOptionSelected) && searchInput.length >= 3) {
      setHasSearched(true); // We're performing a search
      setIsLoading(true);
      setSearchFinished(false);
      setIsReFocused(false); // Reset isReFocused after making a request

      const fetchData = async () => {
        try {
          const response = await authenticatedFetchAPI(
            `/api/vehicles/search/suggest?q=${searchInput}`
          );
          setSearchResults(response);
          setIsLoading(false);
          setSearchFinished(true);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
          setSearchFinished(true);
        }
      };

      fetchData();
    } else if (searchInput.length < 3) {
      setSearchResults([]);
      setHasSearched(false); // Reset hasSearched when input is cleared or too short
    }
  }, [searchInput, isOptionSelected, isReFocused]);*/


  useEffect(() => {
    if ((isReFocused || !isOptionSelected) && debouncedSearchTerm.length >= 3) {
      setHasSearched(true);
      setIsLoading(true);
      setSearchFinished(false);
      setIsReFocused(false);

      const fetchData = async () => {
        const apiUrl = `${process.env.NEXT_PUBLIC_CLEARWATT_BASE_API}/vehicles/search_suggestions/?search_terms=${encodeURIComponent(debouncedSearchTerm)}`;
        try {
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setSearchResults(data.results); // Assuming 'data' is the JSON response directly containing the results
          setIsLoading(false);
          setSearchFinished(true);
        } catch (error) {
          console.error("There has been a problem with your fetch operation:", error);
          setIsLoading(false);
          setSearchFinished(true);
        }
      };

      fetchData();
    } else if (debouncedSearchTerm.length < 3) {
      setSearchResults([]);
      setHasSearched(false);
    }
  }, [debouncedSearchTerm, isOptionSelected, isReFocused]);


  const handleSearchInputChange = (e) => {
    setIsOptionSelected(false);
    setSearchInput(e.target.value);
  };

  const handleSearchResultClick = (event, result) => {
    event.preventDefault();

    const searchParam = {
      make: result.make,
      model: result.model
    };

    // Only call onSearch if at least one search parameter is defined
    if (result.make || result.model) {
      onSearch(searchParam);
    }

    // Update search input based on available information
    if (result.model) {
      setSearchInput(`${result.make} ${result.model}`);
    } else {
      setSearchInput(`${result.make}`);
    }

    setIsOptionSelected(true);
    setSearchResults([]);
    searchInputRef.current.blur();
  };


  /*const groupSearchResults = () => {
    const groupedMakes = {};
    const groupedModels = {};

    searchResults.forEach((result) => {
      const [make, ...modelArr] = result.split(" ");
      const model = modelArr.join(" "); // To handle models with more than one word.

      // Group by make
      if (!groupedMakes[make]) {
        groupedMakes[make] = {models: {}, count: 0};
      }

      // Group by model under its make
      if (!groupedMakes[make].models[model]) {
        groupedMakes[make].models[model] = 0;
      }

      groupedMakes[make].models[model] += 1;
      groupedMakes[make].count += 1;

      // Group by model
      const fullModelName = `${make} ${model}`;
      if (groupedModels[fullModelName]) {
        groupedModels[fullModelName] += 1;
      } else {
        groupedModels[fullModelName] = 1;
      }
    });

    return {groupedMakes, groupedModels};
  };*/

  const groupSearchResults = () => {
    const makes = {};

    searchResults.forEach((result) => {
      const {make, model, variants} = result;
      if (!makes[make]) {
        makes[make] = {
          models: {},
          totalVariants: 0,
          uniqueModels: 0
        };
      }
      if (!makes[make].models[model]) {
        makes[make].models[model] = variants;
        makes[make].uniqueModels += 1; // Count each unique model
      } else {
        makes[make].models[model] += variants;
      }
      makes[make].totalVariants += variants; // Add variants count
    });

    return makes;
  };


  const highlightMatch = (text, searchInput) => {
    const index = text.toLowerCase().indexOf(searchInput.toLowerCase());
    if (index >= 0) {
      return [
        text.slice(0, index),
        <span className={styles.highlight} key="highlight">
          {text.slice(index, index + searchInput.length)}
        </span>,
        text.slice(index + searchInput.length),
      ];
    }
    return text;
  };

  return (
    <div>
      <div className={isFocused ? styles.overlay : styles.overlayHidden}/>
      <div
        ref={searchContainerRef}
        className={isFocused ? styles.searchContainerFocused : styles.searchContainerBlurred}
      >
        <div className={styles.inputContainer}>
          <img className={styles.searchIcon} alt="" src="icons/search.svg"/>
          <input
            ref={searchInputRef}
            type="text"
            value={searchInput}
            onChange={handleSearchInputChange}
            onFocus={() => {
              setIsFocused(true);
              if (searchInput.length >= 3) {
                setIsReFocused(true); // Set isReFocused to true when there's some text in the input
              }
            }}
            onBlur={() => setIsFocused(false)}
            placeholder="Search for a vehicle..."
            className={styles.searchInput}
          />
          {isLoading && (
            <div className={styles.loader}>
              <img
                className={styles.loaderIcon}
                alt="Loading..."
                src="icons/loading.svg"
              />
            </div>
          )}
          {!isLoading && searchInput.length > 0 && (
            <button
              onClick={() => {
                setSearchInput("");
                // Ensure that the input field loses focus when the clear button is clicked
                searchInputRef.current.blur();
              }}
              className={styles.clearButton}>
              Clear
            </button>
          )}
        </div>
        {isFocused && !hasSearched && (
          <div className={styles.instructionsContainer}>
            Start typing the model or make to see the results
          </div>
        )}
        {isFocused &&
          hasSearched &&
          !isLoading &&
          searchFinished &&
          searchResults.length === 0 && (
            <div
              className={`${styles.nothingContainer} ${
                !isLoading ? styles.active : ""
              }`}>
              No matches for your criteria, please try again...
            </div>
          )}
        {/*{isFocused && searchResults.length > 0 && (
          <div
            className={`${styles.searchResultsContainer} ${
              !isLoading ? styles.active : ""
            }`}>
            <div className={styles.searchResult}>
              <div className={styles.categoryTitle}>Makes found</div>
              <ul className={styles.resultsList}>
                {Object.entries(groupSearchResults().groupedMakes).map(
                  ([make, data]) => (
                    <li
                      key={make}
                      onMouseDown={(event) =>
                        handleSearchResultClick(event, make)
                      }>
                      <span className={styles.makeText}>
                        {highlightMatch(make, searchInput)}
                      </span>
                      <span className={styles.countText}>
                        (
                        {data.models && typeof data.models === "object"
                          ? Object.keys(data.models).length
                          : 0}{" "}
                        models, {data.count} variants)
                      </span>
                    </li>
                  )
                )}
              </ul>
            </div>

            <div className={styles.searchResult}>
              <div className={styles.categoryTitle}>Models found</div>
              <ul className={styles.resultsList}>
                {Object.entries(groupSearchResults().groupedModels).map(
                  ([model, count]) => (
                    <li
                      key={model}
                      onMouseDown={(event) =>
                        handleSearchResultClick(event, model)
                      }>
                      <span className={styles.modelText}>
                        {highlightMatch(model, searchInput)}
                      </span>
                      <span className={styles.countText}>({count})</span>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        )}*/}

        {isFocused && searchResults.length > 0 && (
          <div className={`${styles.searchResultsContainer} ${!isLoading ? styles.active : ""}`}>
            <div className={styles.searchResult}>
              <div className={styles.categoryTitle}>Makes Found</div>
              <ul className={styles.resultsList}>
                {Object.entries(groupSearchResults()).map(([make, data]) => (
                  <li key={make}
                      onMouseDown={(event) => handleSearchResultClick(event, {make, models: data.models})}>
            <span className={styles.makeText}>
              {highlightMatch(make, searchInput)}
            </span>
                    <span className={styles.countText}>
              ({data.uniqueModels} models, {data.totalVariants} variants)
            </span>
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.searchResult}>
              <div className={styles.categoryTitle}>Models Found</div>
              <ul className={styles.resultsList}>
                {searchResults.map((result) => (
                  <li key={`${result.make} ${result.model}`}
                      onMouseDown={(event) => handleSearchResultClick(event, result)}>
            <span className={styles.modelText}>
              {highlightMatch(`${result.make} ${result.model}`, searchInput)}
            </span>
                    <span className={styles.countText}>({result.variants})</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}


      </div>
    </div>
  );
}
