import styles from '@/components/PartnersContainer.module.css'
import { useRef } from 'react'
import { motion } from 'framer-motion'
import useTypewriterOnScroll from '@/utils/useTypewriterOnScroll'
import useRevealOnScroll from '@/utils/useRevealOnScroll'

const PartnersContainer = () => {
  const messageRef1 = useRef(null)
  const messageRef2 = useRef(null)

  const descriptionRef = useRef(null)

  const title1 = ''
  const title2 = 'A selection of our partners and supporters'

  const messages = [title1, title2]
  const refs = [messageRef1, messageRef2]

  const delays = [0, 0]

  const revealRefs = [descriptionRef]
  const revealDelays = [500]

  useTypewriterOnScroll(refs, messages, styles, delays)
  const { revealedIndices } = useRevealOnScroll(revealRefs, revealDelays)

  return (
    <div className={styles.supporters}>
      <div className={styles.supportersContainer}>
        <div className={styles.sectionIntroduction}>
          <h3 ref={messageRef1} className={styles.h3}></h3>
          <h2 ref={messageRef2} className={styles.h2}></h2>
          <div className={styles.sectionDescription}></div>

          <motion.div
            ref={descriptionRef}
            className={styles.description}
            initial={{ opacity: 0, y: 20 }}
            animate={revealedIndices.has(0) ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
          ></motion.div>
        </div>
        <div className={styles.sectionContent}>
          <div className={styles.supporter}>
            <img className={styles.cwPartnerLogo} alt="autotrader logo" src="/partners/autotrader.png" />
          </div>
          <div className={styles.supporter}>
            <img className={styles.cwPartnerLogo} alt="motability logo" src="/partners/motability.png" />
          </div>

          <div className={styles.supporterVertical}>
            <img className={styles.cwPartnerLogoVertical} alt="octopus ev" src="/partners/octopusev.png" />
          </div>

          <div className={styles.supporter}>
            <img className={styles.cwPartnerLogo} alt="one auto api logo" src="/partners/oneautoapi.png" />
          </div>
          <div className={styles.supporterVerticalXL}>
            <img
              className={styles.cwPartnerLogoVerticalXL}
              alt="Department for Transport logo"
              src="/partners/Department_for_Transport.png"
            />
          </div>

          <div className={styles.supporterVerticalXL}>
            <img
              className={styles.cwPartnerLogoVerticalXL}
              alt="OZEV high res logo"
              src="/partners/OZEV_high-res-logo.png"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartnersContainer
