import styles from "@/components/home/v2/B2BSection.module.css"
import Link from "next/link";
import {FaArrowRight} from "react-icons/fa";
import {useRef} from "react";
import useTypewriterOnScroll from "@/utils/useTypewriterOnScroll";
import useRevealOnScroll from "@/utils/useRevealOnScroll";
import {motion} from 'framer-motion';
import Image from "next/image";

function B2BSection() {

  const messageRef1 = useRef(null);
  const messageRef2 = useRef(null);

  const descriptionRef = useRef(null);

  const title_1 = "ClearWatt for business"
  const title_2 = "Are you a business?"

  const messages = [title_1, title_2];
  const refs = [messageRef1, messageRef2];

  const delays = [0, 0];

  const revealRefs = [descriptionRef];
  const revealDelays = [500];
  const {revealedIndices} = useRevealOnScroll(revealRefs, revealDelays);


  //useTypewriterOnScroll(refs, messages, styles, 500);
  useTypewriterOnScroll(refs, messages, styles, delays);

  return (
    <div className={styles.container}>
      <div className={styles.content}>

        <div className={styles.contentRow}>
          <div className={styles.col_1}>
            <Image
              src="/images/b2b_image_clearwatt_01.jpg" // Point to the image path
              alt="B2B Image" // Add suitable alt text
              className={styles.sectionImage}
              fill
              style={{objectFit:"cover"}}
            />
          </div>
          <div className={styles.col_2}>
            <h3 ref={messageRef1} className={styles.h3}></h3>

            <h2 ref={messageRef2} className={styles.h2}></h2>

            <motion.div ref={descriptionRef}
                        className={styles.description}
                        initial={{opacity: 0, y: 20}}
                        animate={revealedIndices.has(0) ? {opacity: 1, y: 0} : {}}
                        transition={{duration: 0.5}}>
              We're helping businesses in a number of different ways from reducing stock time on the forecourt to reducing friction during the defleeting process. 
            </motion.div>

            <Link
              className={styles.CWLink}
              href={"mailto:hello@clearwatt.co.uk"}>
              <div className={styles.CWLinkLabel}>
                Talk to us
              </div>
              <FaArrowRight className={styles.CWLinkIcon} size={24}/>

            </Link>

          </div>
        </div>
      </div>
    </div>
  );
}

export default B2BSection;
