import styles from "@/components/home/v2/KnowledgeSection.module.css"
import React, {useState, useRef, useEffect} from "react";
import useTypewriterOnScroll from "@/utils/useTypewriterOnScroll";
import {motion, useAnimation} from "framer-motion";
import {FaArrowRight} from "react-icons/fa";
import useRevealOnScroll from "@/utils/useRevealOnScroll";
import Umbrella from "@/components/svgs/icons/v2/Umbrella";
import Grants from "@/components/svgs/icons/v2/Grants";
import OutOfPower from "@/components/svgs/icons/v2/OutOfPower";
import Parking from "@/components/svgs/icons/v2/Parking";
import TCO from "@/components/svgs/icons/v2/TCO";
import Leaf from "@/components/svgs/icons/v2/Leaf";
import Home from "@/components/svgs/icons/v2/Home";
import Connectors from "@/components/svgs/icons/v2/Connectors";
import Glossary from "@/components/svgs/icons/v2/Glossary";
import ACDC from "@/components/svgs/icons/v2/ACDC";
import Range from "@/components/svgs/icons/v2/Range";
import Testing from "@/components/svgs/icons/v2/Testing";
import KW from "@/components/svgs/icons/v2/KW";
import Driving from "@/components/svgs/icons/v2/Driving";
import Cog from "@/components/svgs/icons/v2/Cog";
import Buy from "@/components/svgs/icons/v2/Buy";
import Link from "next/link";


function KnowledgeSection() {
  const messageRef1 = useRef(null);

  const descriptionRef = useRef(null);

  const title_1 = "Knowledge"

  const messages = [title_1];
  const refs = [messageRef1];
  const revealRefs = [descriptionRef];
  const revealDelays = [500];

  useTypewriterOnScroll(refs, messages, styles, 500);
  const {revealedIndices} = useRevealOnScroll(revealRefs, revealDelays);


  const sectionNavRef = useRef(null);
  const controls = useAnimation();
  const [pillStyle, setPillStyle] = useState({});
  const [initialized, setInitialized] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);


  const handleClick = (category, index) => {
    // Check if the category is already active
    if (activeCategory === category) return;

    // Check if the navigation section reference is null
    if (!sectionNavRef.current) return;

    // Get all button elements within the navigation section
    const buttons = Array.from(sectionNavRef.current.querySelectorAll(`.${styles.buttonNav}`));
    const button = buttons[index];
    if (!button) return;

    // Calculate position and dimensions for the pill element
    const rect = button.getBoundingClientRect();
    const navRect = sectionNavRef.current.getBoundingClientRect();

    setPillStyle({
      width: `${rect.width}px`,
      left: `${rect.left - navRect.left}px`,
      backgroundColor: getColorByCategory(category),
    });

    // Get the content row cards element and find the category div within it
    const contentRowCards = document.querySelector(`.${styles.contentRowCards}`);
    const scrollContainer = document.querySelector(`.${styles.scrollContainer}`);
    scrollContainer.scrollLeft = 0;

    const currentScrollPosition = scrollContainer.scrollLeft;


    const categoryDiv = contentRowCards.querySelector(`[data-category="${category}"]`);

    // If the category div doesn't exist, return 0 as the default offset
    if (!categoryDiv) return;

    const categoryRect = categoryDiv.getBoundingClientRect();
    const contentRowCardsRect = contentRowCards.getBoundingClientRect();

    const offsetRelativeToContainer = categoryRect.left - contentRowCardsRect.left;

    // Calculate the adjusted offset
    const adjustedOffset = offsetRelativeToContainer - currentScrollPosition;


    // Calculate the x-offset relative to the parent container
    //const offsetRelativeToContainer = categoryDiv.offsetLeft - contentRowCards.offsetLeft;

    // Start the animation
    //controls.start({x: -offsetRelativeToContainer});
    //controls.start({x: -offsetRelativeToContainer, transition: {ease: "easeInOut", duration: 0.7}});
    //controls.start({x: -adjustedOffset, transition: {ease: "easeInOut", duration: 0.7}});
    controls.start({x: -offsetRelativeToContainer, transition: {ease: "easeInOut", duration: 0.7}});


    // Update the active category state
    setActiveCategory(category);
  };


  const getColorByCategory = (category) => {
    const colorMap = {
      "going-electric": "var(--green-1-color)",
      charging: "var(--primary-color)",
      car: "var(--purple-color)",
      community: "var(--magenta-color)",
    };
    return colorMap[category];
  };

  const getTopicOffsetByCategory = (category) => {
    const contentRowCards = document.querySelector(`.${styles.contentRowCards}`);
    const categoryDiv = contentRowCards.querySelector(`[data-category="${category}"]`);

    if (!categoryDiv) return 0;

    return categoryDiv.offsetLeft;
  };

  /*useEffect(() => {
    if (sectionNavRef.current) {
      handleClick("going-electric", 0);
    }
  }, [sectionNavRef.current]);*/

  useEffect(() => {
    if (!initialized) {
      handleClick('going-electric', 0);
      setInitialized(true);
    }
  }, [initialized]);


  return (
    <div className={styles.container}>
      <div className={styles.leftDetail}></div>
      <div className={styles.content}>
        <h2 ref={messageRef1} className={styles.h2}></h2>
        <div className={styles.contentRow}>
          <motion.div ref={descriptionRef}
                      className={styles.description}
                      initial={{opacity: 0, y: 20}}
                      animate={revealedIndices.has(0) ? {opacity: 1, y: 0} : {}}
                      transition={{duration: 0.5}}>
            We're plugging the knowledge gap between consumers and the world of Electric Vehicles (EVs), by providing
            concise, informative digests covering content from charging all the way to financing.
          </motion.div>
        </div>
        <div className={styles.contentRow}>
          <div className={styles.sectionNav} ref={sectionNavRef}>
            <div className={styles.pill} style={pillStyle}></div>
            <div className={styles.buttonNav} onClick={() => handleClick("going-electric", 0)}>Going Electric</div>
            <div className={styles.buttonNav} onClick={() => handleClick("charging", 1)}>Charging</div>
            <div className={styles.buttonNav} onClick={() => handleClick("car", 2)}>Car</div>
            <div className={styles.buttonNav} onClick={() => handleClick("community", 3)}>Community</div>
          </div>
        </div>

        <div className={styles.contentRow}>
          <div className={styles.description_alt}>
            Browse our range of topics below.
          </div>
        </div>

        <div className={styles.scrollAndDetailContainer}>
          <div className={styles.scrollContainer}>

            <motion.div className={styles.contentRowCards} initial={{x: 0}} animate={controls}>
              <div className={styles.topicCategory} data-category="going-electric">
                <Link href='/knowledge/insurance' className={styles.topicCardCat1}>
                  <div className={styles.cardIllustration}>
                    <Umbrella/>
                  </div>

                  <div className={styles.cardTitle}>
                    Insurance
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href='/knowledge/grants-and-incentives' className={styles.topicCardCat1}>
                  <div className={styles.cardIllustration}>
                    <Grants/>
                  </div>
                  <div className={styles.cardTitle}>
                    Grants/incentives
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href='/knowledge/running-out-of-power' className={styles.topicCardCat1}>
                  <div className={styles.cardIllustration}>
                    <OutOfPower/>
                  </div>
                  <div className={styles.cardTitle}>
                    Running out of power
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href='/knowledge/tco' className={styles.topicCardCat1}>
                  <div className={styles.cardIllustration}>
                    <TCO/>
                  </div>
                  <div className={styles.cardTitle}>
                    Total Cost of Ownership
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href='/knowledge/are-evs-green' className={styles.topicCardCat1}>
                  <div className={styles.cardIllustration}>
                    <Leaf/>
                  </div>
                  <div className={styles.cardTitle}>
                    Are EVs green?
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
              </div>
              <div className={styles.topicCategory} data-category="charging">
                <Link href='/knowledge/kw-kwh' className={styles.topicCardCat2}>

                  <div className={styles.cardIllustration}>
                    <KW/>
                  </div>

                  <div className={styles.cardTitle}>
                    kW vs kWh
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href='/knowledge/parking' className={styles.topicCardCat2}>
                  <div className={styles.cardIllustration}>
                    <Parking/>
                  </div>

                  <div className={styles.cardTitle}>
                    Parking
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href="/knowledge/home-charging" className={styles.topicCardCat2}>
                  <div className={styles.cardIllustration}>
                    <Home/>
                  </div>

                  <div className={styles.cardTitle}>
                    Home charging
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href="/knowledge/connectors-tech-specs" className={styles.topicCardCat2}>

                  <div className={styles.cardIllustration}>
                    <Connectors/>
                  </div>

                  <div className={styles.cardTitle}>
                    Connectors/tech specs
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href='/knowledge/ac-dc-charging' className={styles.topicCardCat2}>
                  <div className={styles.cardIllustration}>
                    <ACDC/>
                  </div>

                  <div className={styles.cardTitle}>
                    AC vs DC charging
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
              </div>
              <div className={styles.topicCategory} data-category="car">
                <Link href='/knowledge/understanding-range' className={styles.topicCardCat3}>
                  <div className={styles.cardIllustration}>
                    <Range/>
                  </div>

                  <div className={styles.cardTitle}>
                    Understanding range
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href='/knowledge/how-evs-work' className={styles.topicCardCat3}>
                  <div className={styles.cardIllustration}>
                    <Cog/>
                  </div>
                  <div className={styles.cardTitle}>
                    How does an EV work?
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href='/knowledge/getting-an-ev' className={styles.topicCardCat3}>
                  <div className={styles.cardIllustration}>
                    <Buy/>
                  </div>

                  <div className={styles.cardTitle}>
                    Getting an EV
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href='/knowledge/experience-of-driving-an-ev' className={styles.topicCardCat3}>
                  <div className={styles.cardIllustration}>
                    <Driving/>
                  </div>

                  <div className={styles.cardTitle}>
                    Experience of driving an EV
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
              </div>
              <div className={styles.topicCategory} data-category="community">
                <Link href='/glossary' className={styles.topicCardCat4}>
                  <div className={styles.cardIllustration}>
                    <Glossary/>
                  </div>

                  <div className={styles.cardTitle}>
                    Glossary
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>
                <Link href='/testing-guide' className={styles.topicCardCat4}>
                  <div className={styles.cardIllustration}>
                    <Testing/>
                  </div>

                  <div className={styles.cardTitle}>
                    Testing Guide
                  </div>
                  <div className={styles.cardAction}>
                    <div className={styles.cardLabel}>Go</div>
                    <FaArrowRight className={styles.cardIcon} size={24}/>
                  </div>
                </Link>

              </div>

            </motion.div>
          </div>
          <div className={styles.rightDetail}></div>
        </div>

        <Link
              className={styles.CWLink}
              href="/knowledge">
              <div className={styles.CWLinkLabel}>
                Go to the knowledge hub
              </div>
              <FaArrowRight className={styles.CWLinkIcon} size={24}/>

            </Link>
      </div>
    </div>
  );
}

export default KnowledgeSection;
