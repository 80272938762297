import styles from "@/components/home/v2/illustrations/AnalysisIllustration.module.css"

import MachineLearning from "@/components/svgs/icons/v2/MachineLearning";
import Regression from "@/components/svgs/icons/v2/Regression";
import Chart from "@/components/svgs/icons/v2/Chart";

const AnalysisIllustration = () => {

  return (

    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.element}>
          <MachineLearning/>
        </div>
        <div className={styles.element}>
          <Regression/>
        </div>
        <div className={styles.element}>
          <Chart/>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>ClearWatt Engine</div>
      </div>

    </div>

  )
}

export default AnalysisIllustration;

