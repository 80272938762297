import {useRef} from "react";

import styles from "@/components/home/v2/HeroSection.module.css"
import {FaArrowRight} from 'react-icons/fa';
import Link from "next/link";
import Image from 'next/image';
import useTypewriterOnScroll from "@/utils/useTypewriterOnScroll";
import useRevealOnScroll from "@/utils/useRevealOnScroll";

import {motion} from 'framer-motion';
import LinkScroll from "@/components/LinkScroll";


function HeroSection() {


  const messageRef1 = useRef(null);
  const messageRef2 = useRef(null);

  const descriptionRef = useRef(null);

  const title_1 = "Welcome to ClearWatt"
  const title_2 = "The second-hand \n electric revolution"

  const messages = [title_1, title_2];
  const refs = [messageRef1, messageRef2];
  const delays = [500, 900];

  const revealRefs = [descriptionRef];
  const revealDelays = [1000];

  const {revealedIndices} = useRevealOnScroll(revealRefs, revealDelays);

  useTypewriterOnScroll(refs, messages, styles, delays);


  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentRow}>
          <div className={styles.col_1}>
            <h3 ref={messageRef1} className={styles.h3}></h3>
            <h1 ref={messageRef2} className={styles.h1}></h1>

            <motion.div ref={descriptionRef}
                        className={styles.description}
                        initial={{opacity: 0, y: 20}}
                        animate={revealedIndices.has(0) ? {opacity: 1, y: 0} : {}}
                        transition={{duration: 0.5}}>
              ClearWatt is a platform designed to make buying, selling and owning electric vehicles hassle-free. We
              provide comprehensive information, tools, and a community of support to help you embrace the electric era
              with complete confidence.
            </motion.div>

            <LinkScroll
              className={styles.CWButton}
              href="#beta-program">
              <div className={styles.CWButtonLabel}>
                Validate the health of my EV
              </div>
              <FaArrowRight className={styles.CWButtonIcon} size={24}/>

            </LinkScroll>


          </div>
          <div className={styles.col_2}>
            <Image
              src="/images/hero_image_clearwatt_02.jpg"
              alt="ClearWatt Hero Image"
              className={styles.heroImage}
              fill
              style={{objectFit:"cover"}}
              priority
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
