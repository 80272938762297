import {useRef} from "react";
import useTypewriterOnScroll from "@/utils/useTypewriterOnScroll";
import useRevealOnScroll from "@/utils/useRevealOnScroll";
import {motion} from 'framer-motion';
import Image from 'next/image';


import styles from "@/components/home/v2/AboutSection.module.css"

function AboutSection() {

  const messageRef1 = useRef(null);
  const messageRef2 = useRef(null);

  const messageRef3 = useRef(null);
  const messageRef4 = useRef(null);
  const messageRef5 = useRef(null);

  const descriptionRef = useRef(null);


  const title_1 = "About ClearWatt"
  const title_2 = "Empowering the (second-hand) electric revolution"

  const title_3 = "Clarity"
  const title_4 = "Reassurance"
  const title_5 = "Certainty"

  const messages = [title_1, title_2, title_3, title_4, title_5];
  const refs = [messageRef1, messageRef2, messageRef3, messageRef4, messageRef5];

  const delays = [0, 0, 0, 0, 0];

  const revealRefs = [descriptionRef];
  const revealDelays = [500];

  //useTypewriterOnScroll(refs, messages, styles, 500);
  useTypewriterOnScroll(refs, messages, styles, delays);
  const {revealedIndices} = useRevealOnScroll(revealRefs, revealDelays);


  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentRowTwo}>
          <div className={styles.col_1}>
            <h3 ref={messageRef1} className={styles.h3}></h3>
            <h2 ref={messageRef2} className={styles.h2}></h2>
            <motion.div ref={descriptionRef}
                        className={styles.description}
                        initial={{opacity: 0, y: 20}}
                        animate={revealedIndices.has(0) ? {opacity: 1, y: 0} : {}}
                        transition={{duration: 0.5}}>
              At ClearWatt, we believe that the shift to EVs should be simple and accessible for everyone.
            </motion.div>
          </div>


        </div>

        <div className={styles.contentRow}>
          <div className={styles.col_1}>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <Image alt='Clarity' className={styles.cwIllustration} src='/illustrations/B01.svg' width={201} height={150}></Image>
              </div>
              <div ref={messageRef3} className={styles.cardTitle}></div>
              <div className={styles.cardText}>ClearWatt is the ultimate resource for buyers and sellers of second hand
                electric vehicles (EVs), providing the consumer confidence needed to fully unlock the used market.
              </div>
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <Image alt='Reassurance' className={styles.cwIllustration} src='/illustrations/B02.svg' width={201} height={150}></Image>
              </div>
              <div ref={messageRef4} className={styles.cardTitle}></div>
              <div className={styles.cardText}>We aim to assist our customers through the entire sale and purchase
                process of a used electric vehicle, all the way from vehicle selection to close of sale. Our
                certification process alleviates consumer anxiety for both buyers and sellers of used EVs.
              </div>
            </div>
          </div>
          <div className={styles.col_3}>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <Image alt='Certainty' className={styles.cwIllustration} src='/illustrations/B03.svg' width={201} height={150}></Image>
              </div>
              <div ref={messageRef5} className={styles.cardTitle}></div>
              <div className={styles.cardText}>We provide independent and unbiased certification on the driving range
                and battery performance of a vehicle. All our certificates are issued with unique digital signatures,
                ensuring the highest level of safety and security.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
