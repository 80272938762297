// pages/newIndex.js
import styles from '@/styles/HomeV2.module.css'
import Head from 'next/head'
import Header from '@/components/Header'
import Footer from '@/components/home/v2/Footer'
import HeroSection from '@/components/home/v2/HeroSection'
import AboutSection from '@/components/home/v2/AboutSection'
import KnowledgeSection from '@/components/home/v2/KnowledgeSection'
import B2BSection from '@/components/home/v2/B2BSection'
import BetaFormSection from '@/components/home/v2/BetaFormSection'
import EVHCSection from '@/components/home/v2/EVHCSection'
import DirectorySection from '@/components/home/v2/DirectorySection'
import PartnersContainer from '@/components/PartnersContainer'

function NewHomePage() {
  return (
    <div>
      <Head>
        <title>ClearWatt - The second-hand electric revolution</title>
        <meta
          name="description"
          content="ClearWatt provides transparency throughout the lifetime of an electric car, by measuring and certifying battery health indicators at key touchpoints such as resale transactions."
        />
        <meta name="viewport" content="maximum-scale=1, initial-scale=1, width=device-width" />

        <link rel="icon" href="/favicon.png" />
      </Head>
      <Header />
      <HeroSection />
      <AboutSection />
      <PartnersContainer />
      <EVHCSection />
      <DirectorySection />
      <KnowledgeSection />
      <B2BSection />
      <BetaFormSection />
      <Footer />
    </div>
  )
}

export default NewHomePage
