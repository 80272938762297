import Trip from '@/components/svgs/icons/v2/Trip'
import styles from '@/components/home/v2/illustrations/TestIllustration.module.css'
import Trip2 from '@/components/svgs/icons/v2/Trip2'
import Trip3 from '@/components/svgs/icons/v2/Trip3'

const TestIllustration = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.element}>
          <Trip />
        </div>
        <div className={styles.element}>
          <Trip2 />
        </div>
        <div className={styles.element}>
          <Trip3 />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Trips</div>
      </div>
    </div>
  )
}

export default TestIllustration
